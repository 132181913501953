<template>
    <v-layout>
        <v-flex
            xs12
            md8
            offset-md2>
            <v-card>
                <v-toolbar
                    dark
                    color="blue darken-4">
                    <v-toolbar-title class="white--text">
                        Change Password
                    </v-toolbar-title>
                    <v-spacer />
                </v-toolbar>
                <v-card-text>
                    <v-container
                        fluid
                        grid-list-md>
                        <v-form
                            ref="form"
                            v-model="valid">
                            <v-text-field
                                v-model="currentPassword"
                                label="Current Password"
                                type="password"
                                :rules="passwordRules"
                                required />
                            <v-text-field
                                v-model="newPassword"
                                label="New Password"
                                type="password"
                                :rules="passwordRules"
                                required />
                            <v-text-field
                                v-model="confirmPassword"
                                label="Confirm New Password"
                                type="password"
                                :rules="confirmPasswordRules"
                                required />
                            <v-btn
                                :disabled="!valid || confirmPassword !== newPassword"
                                @click="changePassword()">
                                Reset
                            </v-btn>
                        </v-form>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
    import HttpClient from '../../http';

    export default {
        title: 'Settings',
        data() {
            return {
                valid: false,
                confirmPassword: '',
                newPassword: '',
                currentPassword: '',
                passwordRules: [
                    (v) => (v.length >= 8) || 'Password must be 8 or more characters',
                ],
                confirmPasswordRules: [
                    (v) => (v === this.newPassword && this.confirmPassword !== '') || 'Passwords don\'t match',
                ]
            };
        },
        methods: {
            changePassword() {
                (new HttpClient)
                    .put('/users/current/change_password', {
                        current_password: this.currentPassword,
                        password: this.newPassword,
                        password_confirmation: this.confirmPassword,
                    })
                    .then(() => {
                        this.$flash('Password changed successfully!', 'green');
                        this.clearForm();
                    })
                    .catch(e => {
                        console.log(e);
                        this.$flash('Incorrect password entered.', 'red');
                    });
            },
            clearForm() {
                this.valid = false;
                this.confirmPassword = '';
                this.newPassword = '';
                this.currentPassword = '';
                this.$refs.form.reset();
            }
        },
    };
</script>

<style scoped>

</style>
